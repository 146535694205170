<script setup lang="ts">
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'

import { useJobsStore } from '~/js/stores/jobs'

import { getClasses } from '~/js/composables/classes'
import { getGlobalTranslation } from '~/js/composables/translations'

import { Location } from '~/js/interface/location'

import ButtonPrimary from '~/vue/_atoms/buttons/ButtonPrimary.vue'
import FilterLocation from '~/vue/_molecules/filters/FilterLocation.vue'
import FilterSearch from '~/vue/_molecules/filters/FilterSearch.vue'

interface Props {
    endpoint: string,
    utilities?: string
}

withDefaults(defineProps<Props>(), {
    utilities: '',
})

const city = ref('')
const location = ref('')
const jobsStore = useJobsStore()
const { posts, loading } = storeToRefs(jobsStore)

const classes = ref({
    _default: {
        flex: " flex flex-col",
        space: 'px-4 py-6 gap-6 sm:p-4',
    },
    wrapper: {
        _default: 'w-full',
        space: 'py-4 px-4 sm:px-10 space-y-8',
    }
})

const updateLocation = (loc: Location|string) => {
    if (typeof loc !== 'string') {
        city.value = loc.city
        location.value = loc.coordinates.join(',')
    }
}

const onSubmit = evt => {
    const form = evt.srcElement

    form.querySelectorAll('input').forEach(input => {
        if (input.value == '') {
            input.disabled = true
        }
    })
}

const updateSearch = (search:string, query:string) => {
    jobsStore.setSearch(search, query)
}

const autocompleteJobs = computed(() => {
    if (posts.value) {
        return [...new Set(posts.value.filter(post => post.document.title).map(post => post.document.title))]
    }

    return []
})
</script>

<template>
    <div :class="[getClasses(classes, '_default'), utilities]">
        <form
            method="get"
            :action="endpoint"
            :class="getClasses(classes, 'wrapper')"
            @submit="onSubmit"
        >     
            <div class="w-full md:flex gap-4 lg:gap-8 space-y-4 md:space-y-0">
                <FilterSearch 
                    :label="getGlobalTranslation('Functie')"
                    :loading="loading"
                    :placeholder="getGlobalTranslation('Functie, trefwoord, ...')"
                    :show-autocomplete="true"
                    :show-label="true"
                    @setQuery="updateSearch($event, 'searchQuery')"
                    :autocomplete-results="autocompleteJobs"
                    type="functionQuery"
                    name="functie"
                    utilities="!max-w-full"
                />
                <FilterLocation 
                    :label="getGlobalTranslation('Locatie')"
                    :placeholder="getGlobalTranslation('Plaatsnaam of postcode')"
                    :show-label="true"
                    utilities="!max-w-full"
                    icon="far fa-location-dot"
                    type="locationQuery"
                    @setQuery="updateLocation($event)"
                />
                <input type="hidden" name="city" v-model="city" />
                <input type="hidden" name="location" v-model="location" />
                <div class="flex items-end">
                        <ButtonPrimary
                            type="submit"
                            :content="getGlobalTranslation('Vacature zoeken')"
                            :ga="{
                                category: 'jobs',
                                action: 'filter',
                                label: getGlobalTranslation('Vacature zoeken')
                            }"
                            utilities="w-full md:w-fit !justify-center"
                            />
                </div>
            </div>
        </form>
    </div>
</template>