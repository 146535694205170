<script setup lang="ts">
import { ref, onMounted } from 'vue'

import { getGlobalTranslation } from '~/js/composables/translations'
import { getClasses } from '~/js/composables/classes'

import { Analytics } from '~/js/interfaces/analytics'

import DrawerLogin from '~/vue/_organisms/drawers/DrawerLogin.vue'

interface Props {
    ga?: Analytics | null,
    id: number | null,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    size: 'default',
    utilities: ''
})

const classes = ref({
    _default: 'flex justify-center items-center',
    border: 'border border-yellow-600',
    color: 'text-purple-600',
    focus: 'focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-1',
    font: 'font-primary font-bold',
    active: 'active:bg-yellow-600',
    hover: 'hover:ring-2 hover:ring-yellow-600',
    radius: 'rounded-sm rounded-br-2xl ',
    spacing: 'py-1 px-4 gap-2',
    size: 'w-fit',
    utilities: props.utilities
})
const showLoginDrawer = ref(false)
const like = ref(false)
const loggedIn = ref(false)

const handleLike = (show = true, jobId = null) => {
    showLoginDrawer.value = show

    if (loggedIn.value) {
        onUpsertFavourite()
    }
}

const handleHideLoginDrawer = () => {
    showLoginDrawer.value = false
}

const handleLogIn = () => {
    // fetchFavourites(true)
    showLoginDrawer.value = false
    fetchFavourites(true)
}

const onUpsertFavourite = () => {
    fetch(`/actions/site-module/favourite/upsert-favourites-jobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            [window.csrf.name]: window.csrf.value, // Use the token from the GET request
        },
        body: JSON.stringify({
            favourite: props.id,
            [window.csrf.name]: window.csrf.value, // Use the token in the body as well
        })
    })
        .then(response => response.json())
        .then(data => {
            if (data?.favourites) {
                like.value = data.favourites.includes(props.id)
            }
        })
}

const fetchFavourites = (applyLike = false) => {
    // Fetch favourite jobs with userId as a query parameter
    fetch(`/actions/site-module/favourite/get-favourites`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data?.favourites) {
                loggedIn.value = true
                like.value = data?.favourites.includes(props.id)

                if (!like.value && applyLike) {
                    onUpsertFavourite()
                }

                // onSetFavouriteJobs(data?.favourites)
                // onSetFavouriteSectors(data?.sectors)
            }
        })
}

onMounted(() => {
    fetchFavourites()
})
</script>

<template>
    <!-- not liked -->
    <button
        v-if="!loggedIn || !like"
        @click="() => handleLike(loggedIn == false)"
        :class="[
            getClasses(classes), 
            utilities
        ]"
        :title="ga?.label ?? false"
        :data-tag-category="ga?.category ?? false"
        :data-tag-action="ga?.action ?? false"
        :data-tag-label="ga?.label ?? false"
    >
        <span class="order-2 whitespace-nowrap flex items-center">
            <span class="pt-1">{{ getGlobalTranslation('Bewaar als favoriet') }}</span>
        </span>

        <span class="pt-1 text-xl mr-1">
            <i class="far fa-heart"/>
        </span>
    </button>

    <!-- liked -->
    <button
        v-if="like"
        @click="onUpsertFavourite"
        :class="[
            getClasses(classes), 
            utilities
        ]"
        :title="ga?.label ?? false"
        :data-tag-category="ga?.category ?? false"
        :data-tag-action="ga?.action ?? false"
        :data-tag-label="ga?.label ?? false"
    >
        <span class="order-2 whitespace-nowrap flex items-center">
            <span class="pt-1">{{ getGlobalTranslation('Verwijder als favoriet') }}</span>
        </span>


        <span class="pt-1 text-xl mr-1">
            <i class="fas fa-heart"/>
        </span>
    </button>

    <teleport to="body">
        <DrawerLogin
            @showLoginDrawer="handleHideLoginDrawer"
            @loggedIn="handleLogIn"
            :title="getGlobalTranslation('Log in om je favorieten op te slaan')"
            :description="getGlobalTranslation('Wil je jouw favoriete vacatures eenvoudig opslaan en later terugvinden? Log in op je account en geniet van een gepersonaliseerde ervaring.')"
            :show="showLoginDrawer"
        />
    </teleport>
</template>