<script lang="ts">
import { defineComponent } from 'vue'

import BadgeJobCalculated from '~/vue/_atoms/badges/BadgeJobCalculated.vue'
import CardJob from '~/vue/_molecules/cards/CardJob.vue'
import FiltersJobsLanding from '~/vue/_organisms/filters/FilterJobsLanding.vue'
import ViewJobs from '~/vue/_organisms/views/ViewJobs.vue'
import ButtonFavourite from '~/vue/_atoms/buttons/ButtonFavourite.vue'

export default defineComponent({
    components: {
        'badge--job-calculated': BadgeJobCalculated,
        'card--job': CardJob,
        'filter--jobs': FiltersJobsLanding,
        'view--jobs': ViewJobs,
        'button--favourite': ButtonFavourite
    }
})
</script>