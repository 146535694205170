<script setup lang="ts">
import { ref, onMounted } from 'vue'

import BadgeJob from '~/vue/_atoms/badges/BadgeJob.vue'

import { Job } from '~/js/interface/jobs'

import { getStatus } from '~/js/composables/job'

interface Props {
    card: Job
}

const props = defineProps<Props>()
const sectors = ref([])
const coords = ref({
    latitude: null,
    longitude: null,
})
const status = ref(null)

onMounted(() => {
    // Fetch favourite jobs with userId as a query parameter
    fetch(`/actions/site-module/favourite/get-favourites`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(response => response.json())
        .then(data => {
            if (data?.favourites) {
                sectors.value = data.sectors
            }

            status.value = getStatus(props.card, sectors.value, coords.value)
        })

    // fetch current user position for the perfect match
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            coords.value = position.coords
            status.value = getStatus(props.card, sectors.value, coords.value)
        })
    }
})
</script>

<template>
    <BadgeJob
        v-if="status"
        :status="status"
    />
</template>